import React from "react"

import { withFeaturedArticles } from "./withFeaturedArticles"
import { FeaturedSlider } from "../../Slider/FeaturedSlider/FeaturedSlider"
import { Card } from "../../Articles/Card/Card"

export const FeaturedArticles = withFeaturedArticles(({ title, articles }) => (
  <FeaturedSlider
    id={"article"}
    title={title}
    items={articles}
    Card={Card}
    layout={"article"}
  />
))

export default FeaturedArticles

import React from "react"
import dayjs from "dayjs"
import LocalizedFormat from "dayjs/plugin/localizedFormat"

import { useRoutes } from "../../../hooks/useRoutes"
import { useImage } from "../../../hooks/useImage"
import { getAdditionalText } from "../../../utils/getAdditionalText"

dayjs.extend(LocalizedFormat)

export const withCard =
  Component =>
  ({ name = "Card", item }) => {
    const { linkResolver } = useRoutes()
    const { getGatsbyImage } = useImage()

    const {
      template: {
        article: { additionalReadArticleText },
      },
    } = getAdditionalText()

    const {
      title,
      description,
      image: originalImage,
      imageAsset,
      attributes,
    } = item || {}

    const { publishedAt } = attributes || {}

    const link = linkResolver({ ...item, type: "article" })

    const original = imageAsset
      ? { ...originalImage, asset: imageAsset }
      : originalImage

    const image = getGatsbyImage(original, { maxWidth: 410 })

    const formattedPublishedAt = publishedAt
      ? dayjs(publishedAt).format("LL")
      : null

    Component.displayName = name
    return (
      <Component
        title={title}
        description={description}
        link={link}
        image={image}
        publishedAt={formattedPublishedAt}
        additionalReadArticleText={additionalReadArticleText}
      />
    )
  }

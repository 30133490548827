import React from "react"
import { Link } from "gatsby"

import { withCard } from "./withCard"
import {
  Wrapper,
  PublishedAt,
  ImageWrapper,
  Content,
  Title,
  Description,
} from "./CardStyles"
import { Image } from "../../Image/Image"
import { UnderlinedButton } from "../../Styled/Button"

export const Card = withCard(
  ({
    title,
    description,
    link,
    image,
    publishedAt,
    additionalReadArticleText,
  }) => (
    <Wrapper as={link?.url ? Link : null} to={link.url}>
      <ImageWrapper>
        <Image image={image} ratio={"41/24"} />
      </ImageWrapper>
      <Content>
        {publishedAt?.length > 0 && <PublishedAt>{publishedAt}</PublishedAt>}
        <Title>{title}</Title>
        <Description>{description}</Description>
        <UnderlinedButton>
          <span>{additionalReadArticleText}</span>
        </UnderlinedButton>
      </Content>
    </Wrapper>
  ),
)

import tw from "twin.macro"

export const Wrapper = tw.div``

export const ImageWrapper = tw.div`mb-1-6 md:mb-2-4`

export const Content = tw.div`pb-1`

export const PublishedAt = tw.p`
  text-14 leading-1.78 mb-1-6 block text-grey
`

export const Title = tw.h3`
  text-16 leading-1.2 tracking-10 uppercase font-bold mb-1-6
`

export const Description = tw.p`
  text-14 leading-1.78 text-grey mb-1-6
`
